export const aboutMeta = {
  title: "Über mich",
  description: 'Wer steckt hinter "Webentwicklung Dominik Schratl"? Naja, klar, Dominik Schratl! Mehr Infos zu mir, meiner Ausbildung und meinen Hobbys findest du hier :) ',
}

export const aboutAtf = {
  imageLeft: false,
  imageCenter: false,
  isAtf: true,
  imageColWidth: 6,
  image: "transparent-4",
  alt: "Image of me, kissing my Macbook",
  hasBackground: true,
  heading: "<strong>Hi, wie gehts?</strong>",
  text: '<p>Ich heiße Dominik und wohne in Innsbruck. Ursprünglich komme ich aus Salzburg, genauer gesagt, aus dem wunderschönen Pinzgau.</p><p>Meine ersten Websites habe ich schon in meiner Schulzeit erstellt. Damals war ich 15 und Schüler im "Computer-Zweig" an der HAK Zell am See. Mittlerweile unterrichte ich dort für einen Tag pro Woche.</p><p>Irgendwie hat mich die Web-Entwicklung nie losgelassen. Ich liebe es, konstant neue Dinge zu entdecken, zu lernen und mich weiterzuentwickeln. Wahrscheinlich habe ich deshalb schon während des Studiums (Informatik & Geographie, Lehramt) begonnen, in Innsbrucker Agenturen zu arbeiten.</p><p>Mittlerweile bin ich seit September 2020 selbstständiger Web-Entwickler und Berater in Innsbruck.</p><p>Wenn du mehr über mich erfahren möchtest, findest du hier noch auf dieser Seite noch mehr Infos.</p><p>Oder, wir unterhalten uns über ein gemeinsames Projekt? Ruf mich gerne an oder schreib mir eine Mail!</p>',
}

export const aboutAccordeon = {
  hasBackground: false,
  heading: "<strong>Meine Erfahrungen</strong>",
  text: "<p>Mittlerweile habe ich schon so Einiges gemacht und erlebt. Das findest du hier. Kurz und bündig zusammengefasst.</br></br>Noch mehr Details findest du in meinem PDF-Lebenslauf. </p>",
  accordeons: [
    {
      title: "Web Development",
      text: "Im September 2020 habe ich mich als Webentwickler selbstständig gemacht.</br></br>Davor war ich für über 3 Jahre in zwei Innsbrucker Agenturen als Entwickler und Projektmanager tätig.</br></br>Mein Fokus liegt auf der Entwicklung von Websites mithilfe des Jamstacks und der Entwicklung von Custom Backends mit Laravel.",
    },
    {
      title: "Unterricht",
      text: 'Seit September 2020 bin ich als Lehrer an der BHAK Zell am See tätig. Dort unterrichte ich die Fächer "Angewandte Programmierung (APR)" und "Internet, Multimedia und Contentmanagement (IMCM)".</br></br>Davor habe ich schon an der BHAK in Bregenz ebenfalls IMCM unterrichtet.</br></br>Außderem war ich Dozent an der Tiroler Volkshochschule.',
    },
    {
      title: "Studium & Schulbildung",
      text: '<p>Mein Studium "Informatik" und "Geographie" auf Lehramt habe ich im Jahr 2018 abgeschlossen.</br></br>Besonders mein Informatik-Studium bringt mir auch heute noch sehr viel. Programmierkonzepte, Algorithmen, Komplexität und Softwarearchitektur sind schließlich auch im Web-Bereich an der Tagesordnung.</br></br>Außerdem profitiere ich sehr von meinem Lehramtsstudium, in welchem ich gelernt habe, komplexe Themen einfach zu erklären.</p><p>Schon vor meinem Studium hat die Informatik eine große Rolle in meinem Leben gespielt. Meine Matura habe ich an der BHAK Zell am See im "Computer-Zweig" abgelegt. Dort habe ich schon mit HTML, CSS, PHP und Javascript gearbeitet.</p>',
    },
    {
      title: "PDF - Lebenslauf herunterladen",
      isAgb: true,
    },
  ],
}

export const aboutHobbyAccordeon = {
  hasBackground: false,
  heading: "<strong>Funfacts & Persönliches</strong>",
  text: "<p>Meine Freunde beschreiben mich als gut gelaunt, gesellig und wunderschön. Zwei Drittel dieser Aussage sind nicht gelogen.</br></br>Auch nicht gelogen sind diese persönlichen Einblicke über mich: </p>",
  accordeons: [
    {
      title: "Salzburger Wurzeln",
      text: '<p>Ich komme aus einer kleinen "Stadt" im Salzburger Pinzgau: Mittersill. Mittersill kennt man noch am ehesten für seine Berge und den Tourismus - Kitzbühel ist auch weniger als 25 Minuten entfernt.</p><p>Neben meiner Familie verbindet mich auch der Fussball mit Salzburg: Ich bin leidenschaftlicher Anhänger des FC Red Bull Salzburg.</p><p>Mozart? Den kenne ich natürlich auch. Sound of Music habe ich aber nie gesehen.</p>',
    },
    {
      title: "Sport in der Halle, Sport in der Bar",
      text: "<p>In meiner Freizeit spielt Sport eine (mittel?)große Rolle.</p><p>Ein- bis zweimal pro Woche findet man mich in der Innsbrucker Kletterhalle beim Bouldern.</p><p>Als Fussballfan findet man mich auch manchmal im Stadion. Die Salzburger Red-Bull-Arena ist aber wirklich weit weg. Deshalb verfolge ich die Spiele auch des öfteren in der TnT-Sportsbar in Innsbruck. Sehr zu empfehlen ;)</p>",
    },
    {
      title: "Schnapp sie dir alle!",
      text: '<p>"Alle Informatiker sind Nerds"  - was für ein Vorurteil. Bei mir trifft das aber zu.</p><p>Ich bin ein riesiger Pokemon-Fan und sammle auch Yu-Gi-Oh Karten. Außerdem bin ich (wohl ein bisschen zu sehr) Fan von den einschlägigen Filmen und Serien: Das Marvel Cinematic Universe, die DC-Filme, Herr der Ringe, Game of Thrones , ...</p><p>Zumindest kenne ich mich in Westeros besser aus als in Europa. Und das, obwohl ich Geographie studiert habe.</p><p>Nur mit Star Wars habe ich echt nichts am Hut. Sorry not sorry.</p>',
    },
  ],
}

export const aboutImages = {
  hasBackground: true,
  images: [
    {
      image: "about-1",
      alt: "Image of me, standing with laptop",
    },
    {
      image: "about-2",
      alt: "Image of me, sitting with laptop",
    },
    {
      image: "about-7",
      alt: "Image of me, thinking",
    },
  ],
}

export const aboutHobbyImages = {
  hasBackground: true,
  images: [
    {
      image: "about-4",
      alt: "Image of me, next to a lake",
    },
    {
      image: "about-5",
      alt: "Image of me, with sunglasses",
    },
    {
      image: "about-6",
      alt: "Image of me, at a bridge",
    },
  ],
}
