import * as React from "react"
import Accordeons from "src/components/accordeons"
import Footer from "src/components/footer"
import ImageGrid from "src/components/image-grid"
import Layout from "src/components/layout"
import Seo from "src/components/seo"
import TwoCols from "src/components/twoCols"
import footerData from "src/data/global/footer"
import { aboutMeta, aboutAccordeon, aboutAtf, aboutHobbyAccordeon, aboutHobbyImages, aboutImages } from "src/data/pages/about/markup"

const AboutPage = () => (
  <Layout>
    <Seo title={aboutMeta.title} description={aboutMeta.description} url="/about" />
    <TwoCols data={aboutAtf} />
    <Accordeons data={aboutAccordeon} />
    <ImageGrid data={aboutImages} />
    <Accordeons data={aboutHobbyAccordeon} />
    <ImageGrid data={aboutHobbyImages} />
    <Footer data={footerData} />
  </Layout>
)

export default AboutPage
