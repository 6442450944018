import classNames from "classnames"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ReactHtmlParser from "react-html-parser"
import { v4 as uuid } from "uuid"

import { cConfettiButton } from "./styles.module.scss"

const ConfettiBtn = ({ text, to, isConfetti, className }) => {
  const confetti = []
  const amount = isConfetti ? 200 : 0

  const confettiBtnClasses = classNames({
    [cConfettiButton]: true,
    [className]: !!className,
  })

  for (let index = 0; index < amount; index += 1) {
    confetti.push(<i key={uuid()} />)
  }

  return (
    <Link to={to} className={confettiBtnClasses}>
      <strong>{ReactHtmlParser(text)}</strong>
      {confetti}
    </Link>
  )
}

ConfettiBtn.defaultProps = {
  isConfetti: false,
  className: ''
}

ConfettiBtn.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  isConfetti: PropTypes.bool,
  className: PropTypes.string,
}

export default ConfettiBtn
