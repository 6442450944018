import classNames from "classnames"
import PropTypes from 'prop-types'
import * as React from "react"
import ReactHtmlParser from "react-html-parser"
import ConfettiBtn from "src/components/confettiBtn"
import DsImage from "src/components/ds-image"

import { cAtfButton, cAtfCol, cAtfImgCol, cAtfImgColCenter, cAtfImgColPadding, cAtfImgColReverse, cAtfImgColSidePadding, cAtfInner, cAtfInnerReverse, cAtfSection, cAtfSectionBackground, cAtfTextCol, cAtfImg } from "./styles.module.scss"

const TwoCols = ({ data }) => {
  const sectionClasses = classNames({
    [cAtfSection]: true,
    [cAtfSectionBackground]: data.hasBackground,
  })

  const sectionInnerClasses = classNames({
    [cAtfInner]: true,
    [cAtfInnerReverse]: !data.imageLeft,
  })

  const textColClasses = classNames({
    [cAtfCol]: true,
    [cAtfTextCol]: true,
  })

  const imageColClasses = classNames({
    [cAtfCol]: true,
    [cAtfImgCol]: true,
    [cAtfImgColReverse]: !data.imageLeft,
    [cAtfImgColCenter]: data.imageCenter,
    [cAtfImgColPadding]: data.imagePadding,
    [cAtfImgColSidePadding]: data.imageLeft && data.imagePadding,
  })

  return (
    <section className={sectionClasses}>
      <div className={sectionInnerClasses}>
        <div className={imageColClasses}>
          <DsImage src={data.image} alt={data.alt} className={cAtfImg} isAtf={data.isAtf} />
        </div>
        <div className={textColClasses}>
          {data.subHeading && <h3>{ReactHtmlParser(data.subHeading)}</h3>}
          {data.heading && <h1>{ReactHtmlParser(data.heading)}</h1>}
          {data.text && <div>{ReactHtmlParser(data.text)}</div>}
          {data.button && (
            <ConfettiBtn
              to={data.button.link}
              className={cAtfButton}
              text={data.button.text}
              isConfetti={data.button.isConfetti}
            />
          )}
        </div>
      </div>
    </section>
  )
}

TwoCols.defaultProps = {
  data: {
    hasBackground: false,
    imageLeft: false,
    imageCenter: false,
    imagePadding: false,
    image: '',
    alt: '',
    isAtf: false,
    subheading: '',
    heading: '',
    text: '',
    button: {
      link: '',
      text: '',
      isConfetti: false
    }
  }
}

TwoCols.propTypes = {
  data: PropTypes.shape({
    hasBackground: PropTypes.bool,
    imageLeft: PropTypes.bool,
    imageCenter: PropTypes.bool,
    imagePadding: PropTypes.bool,
    image: PropTypes.string,
    alt: PropTypes.string,
    isAtf: PropTypes.bool,
    subHeading: PropTypes.string,
    heading: PropTypes.string,
    text: PropTypes.string,
    button: PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string,
      isConfetti: PropTypes.bool
    })
  })
}

export default TwoCols
